import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import LightGallery from 'lightgallery/react';

// Import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// Import plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

// Add this at the top of the file
const SQUARE_TYPES = {
    small: { value: 1, label: "Studio + Suite 1Pn (3 người)" },
    medium: { value: 2, label: "Premium 1Pn+(4 người)" },
    large: { value: 3, label: "Luxury 2Pn+(6 người)" },
    ultra_large: { value: 4, label: "President 3Pn (8 người)" },
};


const formatApartmentNo = (apartmentNo) => {
    return parseInt(apartmentNo, 10) < 10 ? `0${apartmentNo}` : apartmentNo;
};

const getApartmentFloorAndNo = (apartment) => {
    return `${apartment.block}-${apartment.floor}.${formatApartmentNo(apartment.no)}`;
};

export const VIEWS = {
    SEA_VIEW: 'Biển trực diện',
    INTERNAL_VIEW: 'Công viên nội khu',
    LIGHT_HOUSE_VIEW: 'Tượng Kito-Hải đăng',
    HO_MAY_VIEW: 'Hải đăng-Hồ mây',
    BUILDING_VIEW: "Toà nhà",
    JESUS_STATUE_VIEW: "Tượng chúa",
    CITY_VIEW: 'Toà nhà-Thành phố',
    MOUNTAIN_VIEW: 'Sân bay - cảng biển',
};

function Gallery() {
    const [images, setImages] = useState([]);
    const [apartment, setApartment] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { apartmentId } = useParams();
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/apartments/${apartmentId}/images`);
                if (!response.ok) {
                    throw new Error('Failed to fetch images');
                }
                const result = await response.json();

                if (!result?.floor) {
                    setError(`Không tìm thấy căn hộ ${apartmentId}`);
                    return;
                }

                setImages(result.images);
                setApartment({
                    id: result._id,
                    square_type: result.square_type,
                    block: result.block,
                    floor: result.floor,
                    no: result.no,
                    benefits: result.benefits,
                    views: result.views,
                });
            } catch (err) {
                setError(`Không tìm thấy căn hộ ${apartmentId}`);
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, [apartmentId]);

    useEffect(() => {
        const handleScroll = () => {
            setShowScrollTop(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(window.location.href);
            setCopySuccess(true);

            // Reset success message after 2 seconds
            setTimeout(() => {
                setCopySuccess(false);
            }, 2000);
        } catch (err) {
            console.error('Failed to copy URL:', err);
        }
    };

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return (
        <div className="error-container">
            <div className="error-message">{error}</div>
            <style jsx>{`
                .error-container {
                    text-align: center;
                    padding: 40px 20px;
                    font-family: Arial, sans-serif;
                }
                .error-message {
                    color: #666;
                    font-size: 1.2em;
                    margin-top: 20px;
                }
                .back-button {
                    text-decoration: none;
                    color: #333;
                    padding: 8px 16px;
                    border-radius: 4px;
                    background: #f8f8f8;
                    transition: all 0.3s ease;
                    display: inline-block;
                }
                .back-button:hover {
                    background: #eee;
                    transform: translateX(-3px);
                }
            `}</style>
        </div>
    );

    return (
        <div className="gallery-container">
            <nav className="navigation">
                <Link to={`/?id=${getApartmentFloorAndNo(apartment)}`} className="back-button">
                    <span className="arrow">←</span>
                    <span className="text">Hình tiện ích</span>
                </Link>
                <button onClick={copyToClipboard} className="copy-button">
                    {copySuccess ? 'Đã copy link hình gửi khách' : `Copy hình gửi khách`}
                </button>
            </nav>
            <div className="header">
                <div className="title-container">
                    <h1 className="apartment-title">{getApartmentFloorAndNo(apartment)}</h1>
                    <h3 className="apartment-type">
                        {SQUARE_TYPES[apartment?.square_type]?.label || ''}
                    </h3>
                    <div className="apartment-details">
                        {apartment.views && apartment.views.length > 0 && (
                            <div className="detail-section">
                                <h4 className="detail-title">Hướng view:</h4>
                                <ul className="detail-list">
                                    {apartment.views.map((view, index) => (
                                        <li key={index}>{VIEWS[view] || view}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        {apartment.benefits && apartment.benefits.length > 0 && (
                            <div className="detail-section">
                                <h4 className="detail-title">Tiện ích:</h4>
                                <ul className="detail-list benefits-grid">
                                    {apartment.benefits.map((item, index) => (
                                        <li key={index}>{item.name}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="gallery-wrapper">
                <LightGallery
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                    elementClassNames="custom-layout-gallery"
                    mode="lg-fade"
                    closeOnTap={true}
                    hideScrollbar={true}
                    download={false}
                    getCaptionFromTitleOrAlt={false}
                >
                    {images.map((item, index) => (
                        <a
                            key={index}
                            className={`gallery-item ${index === 0 ? 'main-image' : ''}`}
                            href={item.url}
                            data-sub-html={`<h4>Image ${index + 1}</h4>`}
                        >
                            <img
                                alt={`gallery item ${index + 1}`}
                                src={item.url}
                                className="img-responsive"
                                loading="lazy"
                            />
                        </a>
                    ))}
                </LightGallery>
            </div>
            {showScrollTop && (
                <button onClick={scrollToTop} className="scroll-to-top">
                    <span className="arrow">↑</span>
                    <span className="label">Về đầu trang</span>
                </button>
            )}
            <style jsx>{`
                .gallery-container {
                    padding: 20px;
                    max-width: 1200px;
                    margin: 0 auto;
                }

                .navigation {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 60px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 20px;
                    background: rgba(255, 255, 255, 0.95);
                    z-index: 100;
                    border-bottom: 1px solid #eee;
                }

                .back-button {
                    text-decoration: none;
                    color: #333;
                    padding: 8px 16px;
                    border-radius: 4px;
                    background: #f8f8f8;
                    transition: all 0.3s ease;
                    display: inline-flex;
                    align-items: center;
                    gap: 8px;
                }

                .back-button:hover {
                    background: #eee;
                    transform: translateX(-3px);
                }

                .back-button .arrow {
                    font-size: 1.2rem;
                    line-height: 1;
                }

                .back-button .text {
                    font-weight: 500;
                }

                .header {
                    padding: 20px 0;
                }

                .title-container {
                    width: 100%;
                    text-align: center;
                    margin-top: 100px;
                }

                .apartment-title {
                    margin: 0;
                    margin-bottom: 10px;
                }

                .apartment-type {
                    margin: 0;
                    color: #666;
                    font-weight: normal;
                    font-size: 1.1em;
                }

                .gallery-wrapper {
                    width: 100%;
                }

                .custom-layout-gallery {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-gap: 5px;
                }

                .gallery-item {
                    overflow: hidden;
                    position: relative;
                    height: 300px;
                    cursor: pointer;
                    background: #f8f8f8;
                }

                .main-image {
                    grid-column: span 2;
                    grid-row: span 2;
                    height: 605px;
                }

                .img-responsive {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                    will-change: transform;
                }

                .gallery-item:hover .img-responsive {
                    transform: scale(1.03);
                }

                @media (max-width: 768px) {
                    .custom-layout-gallery {
                        grid-template-columns: repeat(2, 1fr);
                    }

                    .main-image {
                        height: 400px;
                    }

                    .gallery-item {
                        height: 200px;
                    }
                }

                @media (max-width: 480px) {
                    .custom-layout-gallery {
                        grid-template-columns: 1fr;
                        grid-gap: 3px;
                    }

                    .main-image {
                        grid-column: span 1;
                        grid-row: span 1;
                        height: 300px;
                    }

                    .gallery-item {
                        height: 250px;
                    }
                }

                .loading, .error {
                    text-align: center;
                    padding: 20px;
                    font-family: Arial, sans-serif;
                }

                .error {
                    color: red;
                }

                /* Override lightGallery default styles */
                :global(.lg-backdrop) {
                    background-color: rgba(0, 0, 0, 0.9);
                }

                :global(.lg-outer) {
                    width: 100%;
                    height: 100%;
                }

                /* Base styles for thumbnail area */
                :global(.lg-outer .lg-thumb-outer) {
                    bottom: 0;
                }

                :global(.lg-outer .lg-inner) {
                    padding-bottom: 0 !important;
                }

                :global(.lg-sub-html) {
                    bottom: 60px !important;
                }

                /* Mobile-specific styles */
                @media (max-width: 768px) {
                    :global(.lg-outer .lg-thumb-outer) {
                        height: 60px !important;  /* Reduce thumbnail height on mobile */
                    }

                    :global(.lg-outer .lg-thumb) {
                        height: 60px !important;  /* Match container height */
                    }

                    :global(.lg-outer .lg-thumb-item) {
                        height: 54px !important;  /* Slightly smaller than container */
                        margin: 3px !important;  /* Reduce margins */
                    }

                    :global(.lg-sub-html) {
                        bottom: 40px !important;  /* Adjust caption position for mobile */
                    }
                }

                /* Style the download button */
                :global(.lg-download) {
                    opacity: 0.8;
                    transition: opacity 0.2s ease;
                }

                :global(.lg-download:hover) {
                    opacity: 1;
                }

                .apartment-details {
                    margin-top: 20px;
                    text-align: left;
                    max-width: 600px;
                    margin-left: auto;
                    margin-right: auto;
                }

                .detail-section {
                    margin-bottom: 15px;
                }

                .detail-title {
                    color: #666;
                    margin: 0 0 8px 0;
                    font-size: 1em;
                    font-weight: 600;
                }

                .detail-list {
                    margin: 0;
                    padding-left: 20px;
                    color: #444;
                    font-size: 0.95em;
                    line-height: 1.4;
                }

                .detail-list li {
                    margin-bottom: 4px;
                }

                @media (max-width: 768px) {
                    .apartment-details {
                        padding: 0 20px;
                    }
                }

                .detail-list.benefits-grid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 8px 20px;
                    padding-left: 20px;
                }

                .benefits-grid li {
                    margin-bottom: 4px;
                }

                @media (max-width: 480px) {
                    .detail-list.benefits-grid {
                        gap: 8px 12px;  /* Slightly reduce gap on mobile */
                        padding-left: 16px;  /* Slightly reduce padding on mobile */
                        font-size: 0.9em;  /* Slightly smaller font on mobile */
                    }
                }

                .scroll-to-top {
                    position: fixed;
                    bottom: 20px;
                    right: 20px;
                    padding: 8px 16px;
                    border-radius: 25px;
                    background: rgba(0, 0, 0, 0.7);
                    color: white;
                    border: none;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    transition: all 0.3s ease;
                    z-index: 1000;
                    opacity: 0.8;
                }

                .scroll-to-top .arrow {
                    font-size: 20px;
                }

                .scroll-to-top .label {
                    font-size: 14px;
                    white-space: nowrap;
                }

                .scroll-to-top:hover {
                    background: rgba(0, 0, 0, 0.9);
                    transform: translateY(-3px);
                    opacity: 1;
                }

                @media (max-width: 768px) {
                    .scroll-to-top {
                        bottom: 15px;
                        right: 15px;
                        padding: 6px 12px;
                    }
                    
                    .scroll-to-top .arrow {
                        font-size: 18px;
                    }
                    
                    .scroll-to-top .label {
                        font-size: 12px;
                    }
                }

                .copy-button {
                    padding: 8px 16px;
                    border-radius: 4px;
                    background: #f8f8f8;
                    border: 1px solid #eee;
                    color: #333;
                    cursor: pointer;
                    font-size: 0.9rem;
                    transition: all 0.3s ease;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                }

                .copy-button:hover {
                    background: #eee;
                    transform: translateY(-1px);
                }

                @media (max-width: 768px) {
                    .copy-button {
                        padding: 6px 12px;
                        font-size: 0.85rem;
                    }
                }
            `}</style>
        </div>
    );
}

export default Gallery; 