import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import LightGallery from 'lightgallery/react';

// Import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// Import plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const loadImages = (folder, len) => {
    const images = [];
    for (let index = 0; index < len; index++) {
        const imageIndex = index < 9 ? `0${index + 1}` : index + 1;
        images.push(`/benefits/${folder}/${folder}-${imageIndex}.jpg`);
    }
    return images;
};

const getApartmentCode = (apartmentId) => {
    return apartmentId.split('-')[1];
};

const images = {
    'bbq': { name: 'BBQ', description: 'BBQ hoạt động từ 8:00 AM-22:00 PM, phí dịch vụ 300k/khu bếp', images: loadImages('bbq', 13) },
    'bridge': { name: 'Cầu', description: 'Cầu Vượt Sóng - điểm check in sống ảo', images: loadImages('cau', 2) },
    'outside': { name: 'Công trình sân nội khu', description: 'Công viên nội khu - đài phun nước', images: loadImages('cong-trinh-sanh-ngoai-khu', 9) },
    'kid-swimming-pool': { name: 'Hồ bơi trẻ em', description: 'Công viên nước trẻ em, mở cửa T5 ->T3 6:00 AM-21:00 PM, T4 bảo trì', images: loadImages('ho-boi-tre-em', 7) },
    'swimming-pool': { name: 'Hồ bơi vô cực', description: 'Hồ bơi vô cực tầng 36(giới hạn người cao trên 1m4). Mở cửa T4->T2 6:00 AM-20:00 PM, T3 bảo trì', images: loadImages('ho-boi-vo-cuc', 9) },
    'inhouse-playground': { name: 'Khu vui chơi trong nhà', description: 'Khu vui chơi trong nhà', images: loadImages('khu-vui-choi-trong-nha', 3) },
    'reading-room': { name: 'Phòng đọc sách', images: loadImages('phong-doc-sach', 9) },
    'gym': { name: 'Phòng gym', description: '', images: loadImages('phong-gym', 2) },
    'sauna': { name: 'Phòng sauna', description: 'Phòng Sauna, mở cửa 6:00 AM - 21:00 PM', images: loadImages('phong-sauna', 1) },
    'party': { name: 'Phòng tiệc', description: '', images: loadImages('phong-tiec', 10) },
    'reception': { name: 'Sảnh tiếp tân', description: '', images: loadImages('sanh-tiep-tan', 6) },
    'yoga': { name: 'Yoga', description: '', images: loadImages('yoga', 2) }
};

function Home() {
    const [showScrollTop, setShowScrollTop] = useState(false);
    const [searchParams] = useSearchParams();
    const previousApartmentId = searchParams.get('id');
    useEffect(() => {
        const handleScroll = () => {
            // Show button when user scrolls down 100px
            setShowScrollTop(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const renderBenefits = () => {
        return Object.entries(images).map(([key, item]) => {
            return (
                <div key={key} className="benefit-category">
                    <div className="gallery-wrapper">
                        <h3>{item?.name}</h3>
                        {item?.description && (
                            <p className="benefit-description">{item.description}</p>
                        )}
                        <LightGallery
                            speed={500}
                            plugins={[lgThumbnail, lgZoom]}
                            elementClassNames="custom-layout-gallery"
                            mode="lg-fade"
                            closeOnTap={true}
                            hideScrollbar={true}
                            download={false}
                            getCaptionFromTitleOrAlt={false}
                        >
                            {item?.images?.map((url, index) => (
                                <a
                                    key={index}
                                    className={`gallery-item ${index === 0 ? 'main-image' : ''}`}
                                    href={url}
                                    data-sub-html={`<h4>${key} - Image ${index + 1}</h4>`}
                                >
                                    <img
                                        alt={`${key} ${index + 1}`}
                                        src={url}
                                        className="img-responsive"
                                        loading="lazy"
                                    />
                                </a>
                            ))}
                        </LightGallery>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="home-container">
            <div className="header">
                <h1 className="main-title">Hình tiện ích</h1>
                {previousApartmentId && (
                    <Link to={`/${getApartmentCode(previousApartmentId)}`} className="apartment-link">
                        <span className="text">Quay lại căn hộ {previousApartmentId}</span>
                        <span className="arrow">→</span>
                    </Link>
                )}
            </div>
            <div className="benefits-section">
                {renderBenefits()}
            </div>
            <div className="header">
                <h1 className="main-title">Vị trí</h1>
            </div>
            <footer className="footer">
                <div className="map-container">
                    <iframe
                        title="The Song Real Location"
                        style={{
                            outline: "none",
                            border: "1px solid #e7e7e7",
                            borderRadius: 4,
                        }}
                        width="100%"
                        height="300"
                        src="https://maps.google.com/maps?width=100%25&amp;height=300&amp;hl=en&amp;q=28%20%C4%90%C6%B0%E1%BB%9Dng%20Thi%20S%C3%A1ch,%20Ph%C6%B0%E1%BB%9Dng%208,%20Th%C3%A0nh%20ph%E1%BB%91%20V%C5%A9ng%20T%E1%BA%A7u,%20B%C3%A0%20R%E1%BB%8Ba%20-%20V%C5%A9ng%20T%C3%A0u%2070000,%20Vietnam+(The%20Song%20Real)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    ></iframe>
                </div>
            </footer>
            {showScrollTop && (
                <button onClick={scrollToTop} className="scroll-to-top">
                    <span className="arrow">↑</span>
                    <span className="label">Về đầu trang</span>
                </button>
            )}
            <style jsx>{`
                .navigation {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    padding: 20px;
                    background: rgba(255, 255, 255, 0.95);
                    z-index: 100;
                    border-bottom: 1px solid #eee;
                }

                .back-button {
                    text-decoration: none;
                    color: #333;
                    padding: 8px 16px;
                    border-radius: 4px;
                    background: #f8f8f8;
                    transition: all 0.3s ease;
                    display: inline-flex;
                    align-items: center;
                    gap: 8px;
                }

                .back-button:hover {
                    background: #eee;
                    transform: translateX(-3px);
                }

                .back-button .arrow {
                    font-size: 1.2rem;
                    line-height: 1;
                }

                .back-button .text {
                    font-weight: 500;
                }

                .main-title {
                    text-align: center;
                    margin: 2rem 0;
                    color: #333;
                    font-size: 2rem;
                    font-weight: 600;
                }

                .benefit-category {
                    margin-bottom: 40px;
                }

                /* .benefit-category h3 {
                    padding: 1rem;
                    margin: 0;
                    text-transform: capitalize;
                    text-align: left;
                    font-size: 1.5rem;
                    color: #333;
                    font-weight: 500;
                    padding-left: 0;
                    margin-left: 10px;
                    margin-bottom: 1rem;
                } */

                .gallery-wrapper {
                    width: 100%;
                    max-width: 1160px;
                    margin: 0 auto;
                    padding: 0 10px;
                }

                .custom-layout-gallery {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 20px;
                }

                .gallery-item {
                    overflow: hidden;
                    position: relative;
                    height: 300px;
                    cursor: pointer;
                    background: #f8f8f8;
                }

                .img-responsive {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transform: scale(1);
                    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                    will-change: transform;
                }

                .gallery-item:hover .img-responsive {
                    transform: scale(1.03);
                }

                /* Override lightGallery default styles */
                :global(.lg-backdrop) {
                    background-color: rgba(0, 0, 0, 0.9);
                }

                :global(.lg-outer) {
                    width: 100%;
                    height: 100%;
                }

                :global(.lg-outer .lg-thumb-outer) {
                    bottom: 0;
                }

                :global(.lg-outer .lg-inner) {
                    padding-bottom: 0 !important;
                }

                :global(.lg-sub-html) {
                    bottom: 60px !important;
                }

                /* Mobile-specific styles */
                @media (max-width: 768px) {
                    .benefits-section {
                        padding: 0 10px;
                    }

                    .gallery-wrapper {
                        padding: 0 5px;
                    }

                    .custom-layout-gallery {
                        grid-template-columns: 1fr;
                        grid-gap: 15px;
                    }

                    .gallery-item {
                        height: 250px;
                        width: 100%;
                    }

                    :global(.lg-outer .lg-thumb-outer) {
                        height: 60px !important;
                    }

                    :global(.lg-outer .lg-thumb) {
                        height: 60px !important;
                    }

                    :global(.lg-outer .lg-thumb-item) {
                        height: 54px !important;
                        margin: 3px !important;
                    }

                    :global(.lg-sub-html) {
                        bottom: 40px !important;
                    }
                }

                /* Style the download button */
                :global(.lg-download) {
                    opacity: 0.8;
                    transition: opacity 0.2s ease;
                }

                :global(.lg-download:hover) {
                    opacity: 1;
                }

                .footer {
                    margin-top: 4rem;
                    padding: 0 20px;
                }

                .map-container {
                    max-width: 1200px;
                    margin: 0 auto;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    overflow: hidden;
                }

                @media (max-width: 768px) {
                    .footer {
                        margin-top: 2rem;
                        padding: 0 10px;
                    }
                }

                .header {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px 0;
                    margin-bottom: 20px;
                }

                .main-title {
                    text-align: center;
                    margin: 0;
                    color: #333;
                    font-size: 1.8rem;
                    font-weight: 600;
                }

                .apartment-link {
                    position: absolute;
                    right: 20px;
                    text-decoration: none;
                    color: #333;
                    padding: 8px 16px;
                    border-radius: 4px;
                    background: #f8f8f8;
                    transition: all 0.3s ease;
                    display: inline-flex;
                    align-items: center;
                    gap: 8px;
                }

                .apartment-link:hover {
                    background: #eee;
                    transform: translateX(3px);
                }

                .apartment-link .arrow {
                    font-size: 1.2rem;
                    line-height: 1;
                }

                .apartment-link .text {
                    font-weight: 500;
                }

                @media (max-width: 768px) {
                    .header {
                        flex-direction: column;
                        gap: 15px;
                        padding: 15px 0;
                        align-items: flex-end;
                        padding-right: 10px;
                    }

                    .main-title {
                        font-size: 1.5rem;
                        width: 100%;
                        text-align: center;
                    }

                    .apartment-link {
                        position: relative;
                        right: 0;
                        font-size: 0.9rem;
                        padding: 6px 12px;
                        margin-right: 10px;
                    }
                }

                .scroll-to-top {
                    position: fixed;
                    bottom: 20px;
                    right: 20px;
                    padding: 8px 16px;
                    border-radius: 25px;
                    background: rgba(0, 0, 0, 0.7);
                    color: white;
                    border: none;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    transition: all 0.3s ease;
                    z-index: 1000;
                    opacity: 0.8;
                }

                .scroll-to-top .arrow {
                    font-size: 20px;
                }

                .scroll-to-top .label {
                    font-size: 14px;
                    white-space: nowrap;
                }

                .scroll-to-top:hover {
                    background: rgba(0, 0, 0, 0.9);
                    transform: translateY(-3px);
                    opacity: 1;
                }

                @media (max-width: 768px) {
                    .scroll-to-top {
                        bottom: 15px;
                        right: 15px;
                        padding: 6px 12px;
                    }
                    
                    .scroll-to-top .arrow {
                        font-size: 18px;
                    }
                    
                    .scroll-to-top .label {
                        font-size: 12px;
                    }
                }

            `}</style>
        </div>
    );
}

export default Home; 